import React from "react"
import { graphql } from "gatsby"
import { useTranslation, Trans } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { Container, Row, Col, Card, Accordion } from "react-bootstrap"

import { APREMIA_PHONE, APREMIA_PHONE_FORMATTED } from "../params"

import Layout from "../components/layouts/layout"
import SEO from "../components/seo"
import InsuranceDescription from "../components/insuranceDescription"
import CallIcon from "../components/callIcon"
import QuoteForm from "../components/quoteForm"
import StockImage from "../components/stockImage"

import InsuranceImage from "../images/stock-image-30.jpg"
import InsuranceImageMobile from "../images/stock-image-30-mobile.jpg"
import InsuranceImageTablet from "../images/stock-image-30-tablet.jpg"
import Fear from "../images/stock-image-33.jpg"
import Law from "../images/stock-image-35.jpg"
import Money from "../images/stock-image-32-mobile.jpg"
import Banner from "../images/stock-image-31.png"
import BannerTogether from "../images/stock-image-34.png"
import InfoIcon from "../images/icon-info.svg"
import PercentIcon from "../images/icon-percent.svg"
import DollarIcon from "../images/icon-dollar.svg"
import CancelStripeSrc from "../images/cancel-stripe.jpg"

const RoundedBanner = styled.img`
  width: 100%;
  border-radius: 0 0 15px 15px;
`

const BlueSection = styled.div`
  min-height: 528px;
  background-image: url("/images/emp-insurance-bg.jpg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
  background-color: ${props => props.theme.colors["body-color"]};
  @media (max-width: 992px) {
    background-color: transparent;
    background-image: none;
  }
  @media (min-width: 1700px) {
    background-size: 1366px auto;
  }
`

const CardIcon = styled.svg`
  width: 42px;
  margin-bottom: 15px;
`

const FloatingTitle = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  text-align: center;
  width: 100%;
  @media (max-width: 767px) {
    top: 10px;
  }
`

const FloatingTitleLaw = styled.div`
  position: absolute;
  top: 60px;
  left: 60px;
  @media (max-width: 767px) {
    top: 80px;
    left: 30px;
  }
`

const CancelStripeImage = styled.img`
  width: 536px;
  @media (max-width: 767px) {
    width: 100%;
  }
`

const EmploymentInsurance = () => {
  const { t } = useTranslation()

  const choiceCards = [
    {
      title: t("ei_choice_1_title"),
      description: t("ei_choice_1_desc"),
    },
    {
      title: t("ei_choice_2_title"),
      description: t("ei_choice_2_desc"),
    },
  ]

  return (
    <Layout>
      <SEO title={t("employment_insurance")} />

      <InsuranceDescription
        title={t("employment_insurance_title")}
        content={t("employment_insurance_description")}
        actions={
          <>
            <a href={`tel:${APREMIA_PHONE}`} className="btn btn-primary btn-sm">
              <CallIcon /> {APREMIA_PHONE_FORMATTED}
            </a>
            <a href="#quote-form" className="btn btn-primary btn-sm ms-2">
              {t("we_call_you")}
            </a>
          </>
        }
        image={InsuranceImage}
        imageMobile={InsuranceImageMobile}
        imageTablet={InsuranceImageTablet}
      />

      <BlueSection>
        <StockImage src={Money} className="d-lg-none" />
        <Container className="py-md-4 py-lg-6 pb-4 pb-lg-6">
          <Row>
            <Col xs="12" lg={{ span: 6, offset: 6 }}>
              <h3>{t("employment_insurance_title_3")}</h3>
              <ul className="ul ul-normal ul-dark fw-light">
                <li>
                  <Trans>
                    <b>
                      Con los planes del Mercado, no obtiene ninguna
                      contribución del empleador.
                    </b>
                    <br />
                    Con la mayoría de los planes médicos basados en el empleo,
                    su empleador paga parte de su prima mensual. Si en cambio se
                    inscribe en un plan del Mercado, el empleador no contribuirá
                    a sus primas. Mantenga esto en cuenta si compara su seguro
                    basado en el empleo y los planes del Mercado.
                  </Trans>
                </li>
                <li className="mt-4">
                  <Trans>
                    <b>
                      Con los planes del mercado de seguro usted calificaría
                      para ahorros adicionales.
                    </b>
                    <p>
                      Con los planes del mercado de seguro usted calificaría
                      para ahorros adicionales siempre que la oferta de sus
                      empleador no cumpla con los estándares mínimos de
                      accessibilidad y cobertura.
                    </p>
                  </Trans>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </BlueSection>

      <div className="mt-lg-n5 mb-4">
        <Container>
          <Row>
            <Col xs="12" lg className="mb-4">
              <Card className="h-100 pt-3">
                <Card.Body className="d-flex flex-column flex-md-row flex-lg-column align-items-md-start align-items-lg-center text-center text-md-start text-lg-center">
                  <div className="me-md-3 me-lg-0">
                    <CardIcon as={InfoIcon} />
                  </div>
                  <div className="fw-light">
                    <Trans>
                      <b>PARA SU INFORMACIÓN</b>
                      <br />
                      Si tiene una oferta de seguros basada en su empleo, la
                      única manera de calificar para ahorros en un plan del
                      Mercado es si la oferta de seguros de su empleador no
                      cumple con los estándares mínimos de accesibilidad y
                      cobertura.
                    </Trans>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" lg className="mb-4">
              <Card className="h-100 pt-3">
                <Card.Body className="d-flex flex-column flex-md-row flex-lg-column align-items-md-start align-items-lg-center text-center text-md-start text-lg-center">
                  <div className="me-md-3 me-lg-0">
                    <CardIcon as={PercentIcon} />
                  </div>
                  <div className="fw-light">
                    <Trans>
                      <b>"PLANES ACCESIBLES" Y EL 9.61% ESTÁNDAR</b>
                      <p>
                        Un plan médico basado en el empleo se considera
                        "accesible" si la parte de los costos de las primas
                        mensuales que usted pagaria por la cobertura individual
                        más baja cumple con el estándar de valor mínimo del
                        9.61% de su ingreso familiar.
                      </p>
                      <p>
                        Puede pagar más del 9.61% de su ingreso del hogar en
                        primas mensuales si se inscribe en la cobertura del
                        empleo de su cónyuge o de algún familiar. Pero la
                        accesibilidad se determina sólo por la cantidad que
                        pagaría por la cobertura individual.
                      </p>
                    </Trans>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col xs="12" lg className="mb-4">
              <Card className="h-100 pt-3">
                <Card.Body className="d-flex flex-column flex-md-row flex-lg-column align-items-md-start align-items-lg-center text-center text-md-start text-lg-center">
                  <div className="me-md-3 me-lg-0">
                    <CardIcon as={DollarIcon} />
                  </div>
                  <div className="fw-light">
                    <Trans>
                      <b>EL VALOR MÍNIMO ESTÁNDAR</b>
                      <p>
                        Un plan médico cumple con el valor mínimo estándar si
                        cubre por lo menos el 60% del costo total de los
                        servicios médicos para la población estándar y ofrece
                        una cobertura sustancial de los servicios de hospital y
                        médicos.
                      </p>
                      <p>
                        En otras palabras, en la mayoría de los casos, un plan
                        que cumpla con el valor mínimo estándar cubrirá el 60%
                        de los gastos médicos cubiertos. Usted pagaría el 40%.
                      </p>
                    </Trans>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="bg-white pb-5">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col>
              <RoundedBanner src={Banner} />
            </Col>
          </Row>
          <Row className="gx-0 gx-md-3 mt-4 mt-md-5">
            <Col
              xs="12"
              md="8"
              className="equalize-padding-x mx-auto text-center"
            >
              <h1>{t("employment_insurance_title_2")}</h1>
              <span className="fw-light render-tags">
                {t("employment_insurance_description_2")}
              </span>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="py-md-5">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3 mb-4">
            <Col
              xs="12"
              lg={{ span: 6, order: "first" }}
              className="equalize-padding-x"
            >
              <h1>{t("employment_insurance_title_4")}</h1>
              <p className="fw-light">{t("employment_insurance_desc_4")}</p>
              <ul className="ul ul-normal">
                <li>{t("ei_li_1")}</li>
                <li>{t("ei_li_2")}</li>
              </ul>
            </Col>
            <Col xs={{ span: 12, order: "first" }} lg>
              <StockImage src={Fear} />
            </Col>
          </Row>

          <div className="equalize-padding-x">
            {choiceCards.map((item, index) => (
              <Card key={index} className="mb-4 p-md-4">
                <Card.Body>
                  <Row>
                    <Col xs md="2">
                      <h3>{t("enumerate_option", { number: index + 1 })}</h3>
                    </Col>
                    <Col xs="12" md>
                      <h3>{item.title}</h3>
                      <p className="fw-light render-tags">{item.description}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            ))}
          </div>
        </Container>
      </div> */}

      <div className="pb-4 pb-md-5">
        <Container className="gx-0 gx-md-3">
          <Row className="gx-0 gx-md-3">
            <Col className="position-relative">
              <RoundedBanner src={BannerTogether} />
              <FloatingTitle>
                <h1>{t("employment_insurance_title_5")}</h1>
              </FloatingTitle>
            </Col>
          </Row>
          <Row className="gx-0 gx-md-3 mt-4 mt-md-5">
            <Col
              xs="12"
              lg="6"
              className="equalize-padding-x mx-auto text-center"
            >
              <span className="fw-light render-tags">
                {t("employment_insurance_desc_5")}
              </span>
            </Col>
          </Row>
          <hr className="my-5 d-none d-md-block" />
          <Row className="gx-0 gx-md-3">
            <Col xs="12" lg className="position-relative">
              <StockImage src={Law} className="mt-5 mt-md-0" />
              <FloatingTitleLaw>
                <h1 className="text-white">
                  <Trans>
                    La ley
                    <br />
                    lo protege
                  </Trans>
                </h1>
              </FloatingTitleLaw>
            </Col>
            <Col xs="12" lg className="equalize-padding-x">
              <ul className="ul ul-normal fw-light">
                {new Array(10).fill(undefined).map((_, index) => (
                  <li key={index} className="mt-1">
                    {t(`ei_law_${index}`)}
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>

      {/* <div className="bg-pink py-4 py-md-5">
        <Container className="text-center">
          <CancelStripeImage src={CancelStripeSrc} />
          <h1 className="d-none d-md-block mb-5">
            <Trans>
              Si desea cancelar su plan del
              <br />
              Mercado
            </Trans>
          </h1>
          <h1 className="d-md-none mb-5">
            <Trans>Si desea cancelar su plan del Mercado</Trans>
          </h1>
          <Row>
            {new Array(3).fill(undefined).map((_, index) => (
              <Col key={index} xs="12" lg className="mb-4">
                <Card className="h-100 p-2">
                  <Card.Body>{t(`ei_cancel_${index}`)}</Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div> */}

      <div className="bg-white py-4 py-md-5">
        <Container>
          <h1 className="text-center mb-5">{t("faq")}</h1>
          <div className="px-lg-7">
            <Accordion flush alwaysOpen>
              {new Array(6).fill(undefined).map((_, index) => (
                <Accordion.Item key={index} eventKey={index}>
                  <Accordion.Header>
                    <h3 className="d-none d-md-block m-0">
                      {t(`ei_faq_title_${index}`)}
                    </h3>
                    <h4 className="d-md-none m-0">
                      {t(`ei_faq_title_${index}`)}
                    </h4>
                  </Accordion.Header>
                  <Accordion.Body className="render-tags">
                    <div className="pb-5 pe-md-6 fw-light">
                      {t(`ei_faq_text_${index}`)}
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          </div>
        </Container>
      </div>

      <QuoteForm bordered />
    </Layout>
  )
}

export default EmploymentInsurance

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
